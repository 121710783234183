<template>
  <div>
    <li v-for="(menu, index) in items"
        :key="index">

        <button
          :class="[{ 'is-open' : activeMenu == menu.type }, { 'enable-hover' : isHovered && activeMenu == menu.type }]"
          @mouseover="openOnHover ? open(menu.type) : null"
          @click="open(menu.type)"
        >
          <!-- :is="getMenuLink(menu) ? 'nuxt-link' : 'button'"
          :to="getMenuLink(menu)" -->
          {{ menu.title | getTitle }}
          <SvgIcon v-if="menu.type === 'makeup'" name="g" :title="$config.siteName" />
          <SvgIcon v-if="!openOnHover" name="chevron-down" />
        </button>

        <transition name="fade-fast">
          <div v-if="activeMenu === menu.type"
            class="header__dropdown"
            :class="[ 'header__dropdown--' + type, { 'header__dropdown--click' : !openOnHover } ]"
          >
            <!-- Use "Shop"  "Blog", "About", or "Studios" component-->
            <component :is="menuComponent(menu)" :menu="menu" />
            <button v-if="!openOnHover" @click="closeHeaderMenu" class="header__dropdown__close"><SvgIcon name="close" /></button>
          </div>
        </transition>
    </li>
    <div v-if="openOnHover" class="overlay-bar" ref="overlayBar" @mouseover="disableOverlay"></div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  const SvgIcon = () => import('@/components/global/SvgIcon');
  const Shop = () => import('@/components/header/dropdowns/Shop');
  const Blog = () => import('@/components/header/dropdowns/Blog');
  const About = () => import('@/components/header/dropdowns/About');
  const Studios = () => import('@/components/header/dropdowns/Studios');

  export default {
    name: 'Dropdown',

    components: {
      SvgIcon,
      Shop,
      About,
      Blog,
      Studios
    },

    props: {
      type: {
        type: String,
      },

      items: {
        type: Array,
        required: true
      },

      openOnHover: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      ...mapState({
        isHovered: state => state.header.isHovered,
        activeMenu: state => state.header.activeMenu,
      }),
      isDevice() {
        return process.client
          ? 'ontouchstart' in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0
          : false
      }
    },

    methods: {
      ...mapActions([
        'openHeaderMenuDropdown',
        'closeHeaderMenu'
      ]),

      toggleOpen(type) {
        if(this.activeMenu == type) {
          this.closeHeaderMenu();
        } else {
          this.openHeaderMenuDropdown(type, this.openOnHover);
        }
      },

      open(type) {
        this.openHeaderMenuDropdown(type, this.openOnHover);

        if (!this.isDevice) {
          this.$refs.overlayBar.classList.add('enabled');
        }
      },

      disableOverlay() {
        setTimeout(() => this.$refs.overlayBar.classList.remove('enabled'), 150)
      },

      menuComponent(menu) {
          if (this.type !== 'learn') return this.type

          return menu.type
      },

      // getMenuLink(menu) {
      //   if(menu.to) return menu.to;
      //
      //   if(this.type == 'shop') return `/shop/${menu._meta.uid}`;
      // }
    },

    mounted() {
      document.addEventListener('keyup', (event) => {
        if (event.keyCode === 27) {
          this.closeHeaderMenu();
        }
      });
    }
  }
</script>
